import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/SEO'

const post = {
  title: "Politique de cookies - Arti'mouss",
  description: 'Voici le bla bla fait par des avocats pour des avocats, en cas de question envoyez moi un email !',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}



const PolitiqueConfidentialite = () => {
  return (<>
    <Seo title={post.title} description={post.description} image={post.image}/>

    <Layout>
        <div className='main'>
          <div className='main-content'>
            <div className='mini-content'>
            <h1>Politique de cookies</h1>
            <h2>Pourquoi nous utilisons des cookies</h2>
<p>Lorsque vous visitez un site Web, celui-ci peut stocker ou récupérer des informations sur votre navigateur, principalement par l'intermédiaire de «cookies».
<br/><br/>
Ces informations, qui peuvent concerner vos préférences, ou votre appareil internet (ordinateur, tablette ou mobile), sont principalement utilisées pour faire fonctionner le site comme vous le souhaitez.
<br/><br/>
Elles ne sont pas identifiables personnellement, mais peuvent être utilisées pour vous offrir une expérience Web plus personnalisée.
</p>

<h2>Cookies Analytics</h2>
<p>Nous utilisons le service Google Analytics.
<br/><br/>
Cet outil recueille des informations nous permettant de comprendre les interactions avec notre site Web, d'affiner cette expérience en améliorant le site mais aussi et surtout de mieux comprendre nos visiteurs (vous !).
<br/><br/>
Par exemple : Quelles sont les thématiques les plus populaires ? (afin de produire davantage de contenu) Quelles sont les meilleures sources de trafic ? (afin de comprendre où nous devons concentrer nos efforts marketing) Combien de nos visiteurs nous contactent, visitent nos profils sociaux, après avoir vu quelles pages ? D'ou viennent-ils ? Sont-ils plutôt sur mobile ou sur ordinateur ? Sont-ils plutôt jeunes ou vieux ? Etre capable de répondre à ces questions nous aide à vous offrir une meilleure expérience mais aussi à investir pour intéresser les bonnes personnes, de manière éclairée.
<br/><br/>
Soyons clairs : les données de chaque visiteur individuel ne nous intéressent pas.
<br/><br/>
Nous n'y avons de toute façon pas accès.
<br/><br/>
En revanche, les données agrégées de l'ensemble de nos visiteurs nous aident et sont précieuses pour les raisons pré-citées.
<br/><br/>
Notez bien qu'il ne s'agit pas d'informations personnelles au sens propre du terme.
<br/><br/>
A aucun moment nous n'accédons à votre adresse email, à votre nom, à votre age, à votre adresse, etc...
<br/><br/>
Au mieux (avec votre consentement, à confirmer ou non ci-dessous), nous disposerons d'estimations de tranches d'âge, de répartitions géographiques, de types d'appareils utilisés, d'une mesure des actions réalisées sur notre site (au-dela d'une simple identification des pages consultées).
<br/><br/>
Nous pourrons par exemple savoir si vous avez visionné une vidéo, télécharger un document, cliqué sur un lien spécifique, etc...
<br/><br/>
Mais encore une fois, nous le saurons de manière agrégée, jamais à titre personnel.
<br/><br/>
Par défaut, sans votre consentement, nous avons paramétré Google Analytics de sorte à ce qu'il ne recueille que des informations basiques d'utilisation du site.
<br/><br/>
Quelles pages ? Quelles sources de trafic ? Cela ne va pas plus loin.
<br/><br/>
Il ne collectera pas votre adresse IP non plus.
<br/><br/>
En refusant le consentement ci-dessous, ce cookie (valable 12 mois) sera malgré tout déposé sur votre navigateur, puisque paramétré ainsi, la loi nous l'autorise.
<br/><br/>
(Notez que vous avez la possibilité de le bloquer ou le supprimer en modifiant les paramètres de votre navigateur ou en installant le module complémentaire de navigateur pour la désactivation de Google Analytics).
<br/><br/>
Par contre, en donnant votre consentement, le cookie sera également déposé mais les informations dont nous disposerons seront enrichies.
<br/><br/>
Nous pourrons ainsi analyser de manière agrégée (jamais de manière individuelle, cela ne nous intéresse pas) qui sont nos visiteurs qui ont le plus de valeur.
<br/><br/>
Nous pourrons faire un profilage global, continuer à offir des contenus qui plaisent mais aussi si nous le souhaitons et avec d'autres outils (cités plus bas), afficher des publicités ciblées auprès de personnes pertinentes : soit vous-même, parce que vous avez visité ce site et autorisé ces fonctionnalités, soit des personnes ayant des centres d'intérêt relativement proches.
<br/><br/>
Pour obtenir ces fonctionnalités enrichies, le cookie Analytics récoltera également votre adresse IP à des fins de reporting géographique des visites.
<br/><br/>
Nous n'y avons pas accès directement en tant qu'utilisateurs du service mais votre adresse IP sera cependant envoyée à Google.
</p>      
            </div>
            
          </div>
        </div>

    </Layout>
    </>
  )
}

export default PolitiqueConfidentialite